.banner1{
    background: url('../../assets/images/bannerImg2.png');
   
    height: 80vh;
    width: 100%;
    display: flex;
    align-items: flex-end;
    background-color: #000;
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
}

.banner1 .banner2{
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    width: 40%;
    padding: 3% 5%;
    
    backdrop-filter: blur(32px);
    background: rgba(255, 255, 255, 0.11);
    border-radius: 22px 22px 0px 0px;
    .PictureRow{
        display: flex;
        flex-direction: row;
        width: 100%;
        flex: 1;
        justify-content: center;
        .picture1, .picture2{
            align-self: baseline;
           
            padding: 1% 2%;
            img{
                width: 100%;
            }
        }
        .picture1{
            width: 44%;
        }
        .picture2 {
            width: 56%;
        }
        .picture4{
            width: 47%;
        }
        .picture3{
            width: 53%;
        }
        .picture3, .picture4{
            align-self: end;
            padding: 1% 2%;
          
            img{
                width: 100%;
            }
        }
    }
}
.banner1 .banner2 h1{

color: #fff;
font-size: 2rem;
}
.banner1 .banner2 p{
color: #fff;
font-size: 0.8rem;
}
.banner1 .banner2 .got{
cursor: pointer;
color: aqua;
}
@media only screen and (max-width: 600px) {
    .banner1{
        background-position: center;
        height: 60vh;
        .banner2{
            width: 100%;
            margin-left: 0;
            .About{
                h1{
                    font-size: 1rem;
                }
            }
        }
    }
}




