.navbar{
    position: fixed;
    z-index: 1;
    flex: 1;
    top: 0;
    height: 75px;
    width: 100%;
    backdrop-filter: blur(32px);
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 0;
    .Logo{
        padding-left: 10%;
    }
    .NavItems{
        display: flex;
        justify-content: space-around;
        color: #fff;
        font-family: "ApercuPro-Regular";
        width: 40%;
        padding-right: 11%;
        font-size: 1rem;
        cursor: pointer;
    }
}

.navbar .logo
{
    position: relative;
    text-decoration: none;
    color: whitesmoke;
    font-size: 1.2rem;
    font-weight: 700;
    left: 5%;
    letter-spacing: 0.5;
    z-index: 999999;
  
}
.navbar ul
{
    position: relative;
}
    
.navbar ul li
{
    list-style-type:none ;
    height: auto;
    width: inherit;
    display: inline-block;
    transition: 0.3s linear;
    
}
.navbar ul li a
{
    position: relative;
    display: inline-flex;
    height: 100;
    width: 100;
    align-items: center;
    padding: 0 10px;
    letter-spacing: 0.5;
    font-weight: 700;
    font-size: 1rem;
    text-decoration: none;
    color: white;
    justify-content: center;
    transition: 0.3s linear;
    font-family: "Apercu Pro Light.otf";
}
.navbar ul li:hover a,
.navbar ul li:active a
{
    background:blue;
    color: white;
}
@media only screen and (max-width: 600px) {
    .navbar{
        flex-direction: column;
        align-content: center;
        justify-content: space-around;
        height: 100px;
        .Logo{
            padding-left: 0%;
        }
        .NavItems{
            width: 100%;
            padding-right: 0;
            font-size: 0.8rem;
            color: rgba(255, 255, 255, 0.753);
        }
    }
}