.group1{
    position: relative;
    width: 80%;
    margin: auto;
height: auto;
background: linear-gradient(83.47deg, rgba(222, 142, 48, 0.71) 0.83%, rgba(88, 225, 234, 0.71) 100%);
padding: 10% 10% 5%;
.grpAbsolute{
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    z-index: -1;
}
}

.group1 .group2
{
margin-left: 5%;
width: 50%;
padding: 3rem 1rem;
text-align: center;
background: rgba(255, 255, 255, 0.86);
backdrop-filter: blur(21px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 0px 20px 0px 20px;
}
.group1 .group2 h1{
    margin-bottom: 10px;
    color: #5E5D5D;
  
}
.group1 .group2 p{
    margin-bottom: 10px;
  cursor: pointer;
    color: #767676;
}
.group1 .group2 .button{
    background: #FEAE45;
    border-radius: 7px;
    margin-top: 2.5rem;
  color: white;
  padding: 1rem 4rem;
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  
  cursor: pointer;
  
}
@media only screen and (max-width: 600px) {
    .group1 .group2 {
        margin-left: 0%;
        width: 100%;
        padding: 1rem 2rem;
        h1{
            font-size: 1.5rem;
        }
        p{
            font-size: 0.8rem;
        }
        .button{
            margin-top: 1rem;
            padding: 0.5rem 2rem;
            font-size: 0.8rem;
        }
    }
    .group1{
        padding: 5% 5% 5%;
        .grpAbsolute{
            display: none;
        }
    }
}



