.pos{
    background-color: #252525;
   display: flex;
   flex-direction: column;
    width: 100%;
    height: 350px;
    justify-content: center;
    padding-left: 20%;
}

h2.pos2{
   color: #fff;
   margin-bottom: 2rem;
}
p.pos3{
   display: flex;
    cursor: pointer;
    font-size: 15px;
    color: #00FFF0;
    margin-bottom: 2rem;
    img{
        margin-right: 5px;
    }
}
.pos .socialmedia{
    
    display: flex;
    flex-direction: row;
    width: 10%;
    justify-content: space-between;
   
}
@media only screen and (max-width: 600px) {
    .pos{
        justify-content: flex-start;
        height: 80vh;
        h2.pos2{
            margin-bottom: 0.8rem;
            margin-top: 0.8rem;
        }
        p.pos3{
            margin-bottom: 0.8rem;
        }
        .socialmedia{
            width: 50%;
        }
    }
}