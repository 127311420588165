.banner
{
    background-color:black;
    display: flex;
    padding-top: 75px;
    height: 100vh;
    padding-left: 10%;
}
.MainImage{
    
   height: 100%;
    img{
        height: 100%;
    }
}
.Text{
    width: 40%;
    justify-content: center;
    color: #fff;
    font-size: 1.4rem;
    font-family: "ApercuPro-Regular";
    align-self: center;
    padding-left: 1.5rem;
    border-left: 5px solid #FF7A00;
}

.banner .text{
    position: absolute;
    top: 500px;
    left: 184px;
    color: white;
    border: 1px solid black;
    padding: 10px;
    width: 329px;
    height: 115px;
    text-align: justify;
    font-weight: 400;
    font-family: sans-serif;
}.banner .line{
    position: relative;
    height: 72px;
    width: 6px;
    background: #FF9811;
    top: 437px;
    left: 160px;
}
@media only screen and (max-width: 600px) {
    .banner{
       
        padding-left: 0%;
        height: 45vh;
        align-items: center;
    }
    .Text{
        font-size: 1rem;
        width: 100%;
       
    }
    .MainImage{
        height: 50%;
        
        
       

    }
}