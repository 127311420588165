
@font-face {
    font-family: "ApercuPro-Bold";
    src: url(./fonts/ApercuProBold.otf) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: "ApercuPro-Regular";
    src: url(./fonts/ApercuProRegular.otf) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: "ApercuPro-Medium";
    src: url(./fonts/ApercuProMedium.otf) format("opentype");
    font-display: swap;
  }
  body{
      font-family: "ApercuPro-Regular";
      background-color: rgba(0, 0, 0, 0.861);
  }