.foot{
width: 100%;
height: 80px;
display: flex;
background: #FEAE45;
position: relative;
}
.foot .text{
    justify-content: center;
    flex-direction: column;
    display: flex;
    color: #fff;
    margin-left: 11%;
}
.foot .form{
    position: absolute;
    width: 322px;
    right: 15%;
    /* height: 332px; */
    bottom: 30%;
    border-radius: 10px;
    /* right: 510px; */
    background-color: white;
}
.foot .form .top{
    position: relative;
    width: 322px;
    height: 44px;
    border-radius: 10px 10px 0px 0px;
    background: #FEAE45;
}
.foot .form .top p{
    position: relative;
   text-align: center;
   color: #fff;
   top: 15px;

}
.foot .form form{
    position: relative;
    width: 246px;
    
    top: 0px;
    left: 42px;
    
}
.foot .form form label{
    display: block;
    margin-bottom: 7px;
    margin-top: 10px;
    color: #999999;
    font-size: 11px;
    
}
.foot .form form button{
    
    background: #FF9811;
border-radius: 6px;
    left: 168px;
    border: none;
    font-family: 'ApercuPro-Regular';
    padding: 0.5rem 1.5rem;
    color: azure;
    margin: 1rem;
    float: right;
}
.foot .form form textarea{
    position: relative;
    background: linear-gradient(180deg, rgba(205, 205, 205, 0.15) 0%, rgba(164, 164, 164, 0.23) 43.23%, rgba(199, 199, 199, 0.23) 98.44%);
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.04);
border-radius: 5px;
border: none;
height: 3rem;
    width: 100%;
}
.foot .form form input{
    position: relative;
    height: 2rem;
    font-family: 'ApercuPro-Regular';
        padding: 0 1rem;
    background: linear-gradient(180deg, rgba(205, 205, 205, 0.15) 0%, rgba(164, 164, 164, 0.23) 43.23%, rgba(199, 199, 199, 0.23) 98.44%);
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.04);
border-radius: 5px;
border: none;
    width: 100%;
}
.ModalWrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: #2b2b2bad;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    max-height: 100vh;
    overflow-y: hidden;
    .text{
        justify-content: center;
        flex-direction: column;
        display: flex;
        color: #fff;
        margin-left: 11%;
    }
    .form{
        position: absolute;
        width: 40%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        /* height: 332px; */
        
        border-radius: 10px;
        /* right: 510px; */
        background-color: white;
    }
    .form .top{
        position: relative;
        width: 100%;
        height: 44px;
        border-radius: 10px 10px 0px 0px;
        background:linear-gradient(to left,#24d7f7 0%,  rgba(120, 17, 173, 0.259) 100%);
    }
    .form .top p{
        position: relative;
       text-align: center;
       color: #fff;
       top: 15px;
    
    }
    .form form{
        position: relative;
        width: 100%;
        
        top: 0px;
        padding: 2rem;
        
    }
    .form form label{
        display: block;
        margin-bottom: 7px;
        margin-top: 10px;
        color: #999999;
        font-size: 11px;
        
    }
    .form form button{
        
        background: linear-gradient(to left,#24d7f7 0%,  rgba(120, 17, 173, 0.259) 100%);
    border-radius: 6px;
        
        border: none;
        font-family: 'ApercuPro-Regular';
        padding: 0.5rem 1.5rem;
        color: azure;
        margin: 1rem;
        float: right;
        &.cancel{
            background: transparent;
            border: 1px solid #1fb1ca;
            color: #1b99af;
        }
    }
    .form form textarea{
        position: relative;
        background: linear-gradient(180deg, rgba(205, 205, 205, 0.15) 0%, rgba(164, 164, 164, 0.23) 43.23%, rgba(199, 199, 199, 0.23) 98.44%);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    border: none;
    height: 3rem;
        width: 100%;
    }
    .form form input[type='file']{
        border: 1px solid #fff !important;
        background: transparent;
        box-shadow:none;
        padding: 0;
        &#file-upload-button{
            height: 100%;
            
        }
    }
    .form form .Buttons{
        display: flex;
        justify-content: space-around;
    }
   .form form input{
      
       &.button#file-upload-button{
           height: 100%;
           
       }
        position: relative;
        height: 2rem;
        font-family: 'ApercuPro-Regular';
        padding: 0 1rem;
        background: linear-gradient(180deg, rgba(205, 205, 205, 0.15) 0%, rgba(164, 164, 164, 0.23) 43.23%, rgba(199, 199, 199, 0.23) 98.44%);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    border: none;
        width: 100%;
    }
}
@media only screen and (max-width: 600px) {
    .foot{
        height: 100px;
        .form{
            right: auto;
            left: 50%;
            transform: translateX(-50%);
            bottom: 85%;
        }
    }
    .ModalWrapper{
        .form{
            position: absolute;
            width: 80%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            /* height: 332px; */
            
            border-radius: 10px;
            /* right: 510px; */
            background-color: white;
        }
    }
}
